import React from "react";

import {
    categoriesList,
    categoriesListWrapper,
    categoryShowAllLink,
    categoryAll,
} from "./categories-list.module.scss";
import { IPostCategory } from "../../../models/post.model";

import CategoryItem from "../../atoms/blog/category-item";
import Link from "../../atoms/link";

export interface ICategoriesList {
    categories: IPostCategory[];
}

export default function CategoriesList({ categories }: ICategoriesList) {
    var postAll : IPostCategory = {
        categoryId: -1,
        businessMeaning: 0,
        name: "WSZYSTKIE",
        slug: '/blog',
        postsIds: []
    }
    return (
        <div className={categoriesListWrapper}>
            <ul className={categoriesList}>
                <Link to="/blog" className={categoryAll}>
                    <CategoryItem category={postAll} />
                </Link>
                {categories.map((category) => (
                    <CategoryItem
                        key={`category-item-${category.categoryId}`}
                        category={category}
                    />
                ))}
            </ul>
            {/* <Link to="/blog" className={categoryShowAllLink}>
                Pokaż wszystkie
            </Link> */}
        </div>
    );
}
