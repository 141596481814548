import React, { useCallback, useState } from "react";
import { graphql } from "gatsby";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { ItemList } from "schema-dts";

import { blog, titleSectionWrapper, categoriesWrapper, titleHeader } from "./blog.module.scss";
import { IPostBrief, IPostCategory } from "../../models/post.model";
import { ITitleProps } from "../atoms/title";
import QueryEdgeNode from "../../models/query-edge-node.model";
import { IAuthor } from "../../models/author.model";
import { ISite } from "../../models/site.model";
import { IPageBase } from "../../models/page-base.model";
import getNodes from "../../utils/get-nodes";
import usePageMetadata from "../../hooks/use-page-metadata";
import createBlogListing from "../../utils/structured-data/create-blog-listing";

import MainLayout from "../../layouts/main-layout";
import Section from "../molecules/section";
import SectionWrapper from "../molecules/section-wrapper";
import BlogList, { IBlogListProps } from "../organisms/blog/blog-list";
import CategoriesList from "../molecules/blog/categories-list";

export interface IBlogProps extends IPageBase {
    readonly data: {
        posts: QueryEdgeNode<IPostBrief>;
        authors: QueryEdgeNode<IAuthor>;
        categories: QueryEdgeNode<IPostCategory>;
        site: ISite;
    };
}

const Blog: React.FC<IBlogProps> = ({ data, pageContext }) => {
    const { paginationKeys, categoryId } = pageContext;
    const { t } = useI18next();
    const { pageTitle } = usePageMetadata(pageContext);
    const [structuredData, setStructuredData] = useState<ItemList>();

    const title: ITitleProps = {
        subtitle: t("common.blog"),
        children: t("posts-section.title"),
        align: "center",
    };

    const posts = getNodes(data.posts);
    const authors = getNodes(data.authors);
    const categories = getNodes(data.categories);

    const handleCreatePostItems: IBlogListProps["onCreatePostItems"] = useCallback((postItems) => {
        setStructuredData(createBlogListing(postItems, data.site.siteMetadata));
    }, []);

    return (
        <MainLayout
            className={blog}
            SEOProps={{
                title: pageTitle,
                structuredData,
            }}
        >
            {/* <SectionWrapper className={titleSectionWrapper}>
                <Section column={"narrow"} title={title} />
            </SectionWrapper> */}
            <div className={titleHeader}>
                <span>{title.subtitle}</span>
                <p>{title.children}</p>
            </div>
            <SectionWrapper className={categoriesWrapper}>
                <Section>
                    <CategoriesList categories={categories} />
                </Section>
            </SectionWrapper>
            <Section>
                <BlogList
                    posts={posts}
                    authors={authors}
                    paginationKeys={paginationKeys}
                    onCreatePostItems={handleCreatePostItems}
                    categoryId={categoryId}
                />
            </Section>
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!, $postIds: [Int!]) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        posts: allPost(
            filter: { articleId: { in: $postIds } }
            sort: { fields: publishedAt, order: DESC }
        ) {
            edges {
                node {
                    ...postBriefFields
                }
            }
        }

        authors: allAuthor {
            edges {
                node {
                    ...authorShortFields
                }
            }
        }

        categories: allPostCategory(filter: { postsIds: { nin: [] } }) {
            edges {
                node {
                    ...postCategoryFields
                }
            }
        }

        site {
            ...siteFields
        }
    }
`;

export default Blog;
